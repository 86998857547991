<template>
  <b-modal id="SectorInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/sectors.svg" class="icon-lg" />
        {{ $t("Sectors.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sector.sectorNameAr"
        :title="$t('Sectors.nameAr')"
        :imgName="'sectors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sector.sectorNameEn"
        :title="$t('Sectors.nameEn')"
        :imgName="'sectors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sector.sectorNameUnd"
        :title="$t('Sectors.nameUnd')"
        :imgName="'sectors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sector.branchNameCurrent"
        :title="$t('Sectors.branchName')"
        :imgName="'branches.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sector.parentSectorNameCurrent"
        :title="$t('Sectors.parentSectorName')"
        :imgName="'sectors.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="sector.managerEmployeeNameCurrent"
        :title="$t('Sectors.managerEmployeeName')"
        :imgName="'employees.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-12'"
        :value="sector.sectorNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {};
  },
  props: ["sector"],
  methods: {},
};
</script>
