<template>
  <div v-if="sectors" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("Sectors.name") }}</th>
            <th>{{ $t("Sectors.branchName") }}</th>
            <th>{{ $t("Sectors.parentSectorName") }}</th>
            <th>{{ $t("Sectors.managerEmployeeName") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("info") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              {{ $t("edit") }}
            </th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(Sector, index) in sectors" :key="index">
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>
              {{ isDataExist(Sector.sectorNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(Sector.branchNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(Sector.parentSectorNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(Sector.managerEmployeeNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(Sector.sectorNotes) }}
            </td>
            <td>
              <button
                v-b-modal.SectorInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setSector(Sector)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsUpdate
                )
              "
            >
              <router-link
                :to="{
                  name: 'SectorEdit',
                  params: {
                    sectorToken: Sector.sectorToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData
                    .usersPrivilegAdditionalSettings.additionalSettingsArchive
                )
              "
            >
              <button
                v-b-modal.SectorDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setSector(Sector)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import { timeToLang, isDataExist } from "@/utils/functions";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["sectors", "pagination"],
  methods: {
    setSector(data) {
      this.$emit("setSector", data);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    isDataExist,
  },
  computed: {},
  async created() {},
};
</script>
