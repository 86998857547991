<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="sectors.sectorsData != undefined && sectors.sectorsData.length != 0"
    >
      <SectorsTable
        v-on:setSector="sectors.sector.fillData($event)"
        :sectors="sectors.sectorsData"
        :pagination="sectors.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="sectors.pagination"
        v-on:changePage="changePage"
      />
      <SectorInfo :sector="sectors.sector" />
      <SectorDelete :sector="sectors.sector" v-on:refresh="getAllSectors()" />
    </div>

    <SectorBtns
      :theFilterData="sectors.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import SectorsTable from "@/components/settings/settingsOther/sectors/SectorsTable.vue";
import SectorInfo from "@/components/settings/settingsOther/sectors/SectorInfo.vue";
import SectorDelete from "@/components/settings/settingsOther/sectors/SectorDelete.vue";
import SectorBtns from "@/components/settings/settingsOther/sectors/SectorBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Sectors from "@/models/settings/settingsOther/sectors/Sectors";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    SectorsTable,
    SectorInfo,
    SectorDelete,
    SectorBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      sectors: new Sectors(),
    };
  },
  methods: {
    changePage(page) {
      this.sectors.pagination.selfPage = page;
      this.getAllSectors();
    },
    search(data) {
      this.sectors.filterData.fillData(data);
      this.sectors.sectorsData = [];
      this.getAllSectors();
    },
    async getAllSectors() {
      this.isLoading = true;
      try {
        const response = await this.sectors.sector.getAllSectors(
          this.language,
          this.userAuthorizeToken,
          this.sectors.pagination,
          this.sectors.filterData
        );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.sectors.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.sectors.sectorsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.sectors.sectorsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.sectors.sectorsData = [];
          this.exceptionImg = "illustrator-api-catch.svg";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.sectors.sectorsData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getAllSectors();
  },
};
</script>
